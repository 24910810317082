import { graphql, Link } from 'gatsby';
import * as React from "react";
import DefaultPage from '../templates/default';

export default function fourOhFourPage(props) {
  return <DefaultPage {...props} />;
}

export const pageQuery = graphql`
  query FourOhFour {
   wpPage (title: {eq: "404"}) {
    ...SEO
    ...GeneratedWpPage
  }
    wp {
      ...GeneratedWp
    }
  }
`;
